import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, List, Text, Title } from '@mantine/core';

export function ShippingAndHandling () {
    return (
        <>
            <HeaderSimple />
            <Container size={"sm"} mt={100}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/shipping-delivery.png"} alt='shipping-delivery' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text>نحن نحرص على توصيل طلباتكم بسرعة وأمان. إليكم بعض المعلومات المهمة حول الشحن والتسليم:</Text>
                    <List size="md">
                        <List.Item>معالجة الطلبات: نقوم بمعالجة الطلبات خلال 24 ساعة من تأكيدها.</List.Item>
                        <List.Item>رسوم الشحن: يتم تطبيق رسوم شحن ثابتة على جميع الطلبات.</List.Item>
                        <List.Item>مناطق التوصيل: نوصل إلى جميع ولايات الجزائر سواء الى مكتب شركة التوصيل او الى با المنزل</List.Item>
                        <List.Item>تتبع الشحنة: ستحصل على رقم تتبع لتتبع طلبك حتى يصل إليك.</List.Item>
                        <List.Item>سياسة التوصيل: تأكد من تقديم عنوان شحن صحيح وكامل. في حال عدم وجود أحد لاستلام الطلب، سنتواصل معك لترتيب موعد آخر.</List.Item>
                    </List>
                    <Text>لأي استفسار، تواصل معنا عبر البريد الإلكتروني: support@bi3li.shop أو الهاتف:  0674014619.</Text>
                    <Text>شكرًا لتسوقكم معنا!</Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}