import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { CategoriesSection, Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { BannerHeader } from '../components/Sections/BannerHeader';
import { Image } from '@mantine/core';

export function Home () {
    return (
        <>
            <HeaderSimple />
            <CategoriesSection />
            <Products />
            <Image src={"/bunner.png"} w={"100%"} h={"auto"} mt={30} />
            <Footer/>
        </>
    );
}